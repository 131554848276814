import { useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { Heading, Label } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { setPersistedState } from '@oui/app-core/src/hooks/usePersistedState';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { graphql } from '@oui/lib/src/graphql/tada';
import { PatientSupporterRelation } from '@oui/lib/src/types';

import ConnectedImg from '@src/assets/connected.svg';
import { PatientRelationPicker } from '@src/components/PatientRelationPicker';
import { SupportPatientDetails } from '@src/components/SupportPatientDetails';
import { RootStackScreenProps } from '@src/types/navigation';

export const ConnectedQuery = graphql(`
  query Connected($token: String!) {
    patientSupporterLinkDetails(token: $token) {
      patientName
      relation
    }
  }
`);

export function Connected(_props: {}) {
  const { $t } = useI18n();
  const navigation = useNavigation<RootStackScreenProps<'Connected'>['navigation']>();
  const route = useRoute<RootStackScreenProps<'Connected'>['route']>();
  const { token } = route.params;

  const { data, loading, error } = useQuery(ConnectedQuery, {
    variables: {
      token,
    },
  });

  const onSubmit = (data: PatientSupporterRelation) => {
    setPersistedState('patientSupporterRelation', data);
    navigation.navigate('SignUp', {
      signupTokenFromActivationCode: token,
    });
  };

  const connectWithPatient = data ? (
    <View testID="Connected_validInvitation" spacing={21}>
      <ConnectedImg
        accessibilityLabel={undefined}
        style={{
          alignSelf: 'center',
        }}
      />

      <Heading
        level={1}
        text={$t({ id: 'Connected_title', defaultMessage: 'Connected' })}
        testID="Connected_title"
        textAlign="center"
      />

      <SupportPatientDetails patientName={data!.patientSupporterLinkDetails.patientName} />
      <PatientRelationPicker
        onSubmit={onSubmit}
        relation={data!.patientSupporterLinkDetails.relation}
      />
    </View>
  ) : null;

  const invalidInvitation = (
    <View testID="Connected_invalidInvitation" spacing={21}>
      <ConnectedImg
        accessibilityLabel={undefined}
        style={{
          alignSelf: 'center',
        }}
      />

      <Heading
        level={1}
        text={$t({ id: 'Connected_notConnectedTitle', defaultMessage: 'Not connected' })}
        testID="Connected_notConnectedTitle"
        textAlign="center"
      />

      <Label
        text={$t({
          id: 'Connected_expiredInvitation',
          defaultMessage: 'Your invitation has expired',
        })}
      />
    </View>
  );

  return (
    <AuthScreenContainer heading={null}>
      <View
        flex={1}
        style={{
          alignItems: 'center',
        }}
      >
        {loading ? <ActivityIndicator testID="Connected_activityIndicator" /> : null}
        {error ? invalidInvitation : connectWithPatient}
      </View>
    </AuthScreenContainer>
  );
}
