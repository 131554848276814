import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { ActivityIndicator } from '@oui/app-core/src/components/ActivityIndicator';
import { Markdown } from '@oui/app-core/src/components/Markdown';
import { Text } from '@oui/app-core/src/components/Text';
import { View } from '@oui/app-core/src/components/View';
import { useCurrentPatient } from '@oui/app-core/src/hooks/useCurrentUser';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import { card, useTheme } from '@oui/app-core/src/styles';
import { graphql } from '@oui/lib/src/graphql/tada';
import { getSupporterSuggestionsForSession } from '@oui/lib/src/supporterSuggestions';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import HighlightedTitle from '../assets/Highlighted_title.svg';
import LightBlub from '../assets/LightBlub.svg';

export const SessionSuggestionsQuery = graphql(`
  query SessionSuggestions($patientID: UUID!) {
    patientByPatientID(patientID: $patientID) {
      __typename
      patient {
        __typename
        ID
      }
      progress {
        content
        completed
      }
    }
  }
`);

const SuggestionsHeader = () => {
  const { $t } = useI18n();
  const { theme } = useTheme();
  return (
    <View
      style={{
        marginTop: 16,
        paddingLeft: 16,
        height: 40,
        justifyContent: 'center',
      }}
    >
      <HighlightedTitle
        accessibilityLabel={undefined}
        style={{
          position: 'absolute',
          left: -2,
        }}
      />
      <View row spacing={6}>
        <LightBlub
          accessibilityLabel={undefined}
          color={theme.color.gray400}
          width={16}
          style={{
            marginTop: -4,
          }}
        />
        <Text
          text={$t({ id: 'SuggestionsHeader_title', defaultMessage: 'Review' })}
          weight="semibold"
        />
      </View>
    </View>
  );
};

export const SessionSuggestions = () => {
  const { user } = useCurrentPatient();

  const { data, loading } = useQuery(SessionSuggestionsQuery, {
    skip: !user,
    variables: {
      patientID: user?.ID as GQLUUID,
    },
  });

  const suggestions = useMemo(() => {
    if (!data || !user) {
      return null;
    }

    const lastCompletedSession = data.patientByPatientID?.progress
      .filter((p) => p.completed)
      .slice(-1)[0];

    if (!lastCompletedSession) {
      return null;
    }

    return getSupporterSuggestionsForSession({
      contentType: lastCompletedSession.content,
    });
  }, [data, user]);

  if (!suggestions) {
    return null;
  }

  return (
    <View testID="SessionSuggestions" style={[card, { borderRadius: 20, overflow: 'hidden' }]}>
      <SuggestionsHeader />

      <View
        style={{
          padding: 16,
          paddingTop: 8,
        }}
      >
        {loading ? (
          <ActivityIndicator
            style={{
              marginTop: 16,
            }}
            testID="NextSession_activityIndicator"
          />
        ) : null}
        {suggestions ? (
          <View testID="SessionSuggestions_content">
            <Markdown
              style={{
                heading1: {
                  fontFamily: 'OpenSansSemiBold',
                },
                heading2: {
                  fontFamily: 'OpenSansSemiBold',
                },
                heading3: {
                  fontFamily: 'OpenSansSemiBold',
                },
                heading4: {
                  fontFamily: 'OpenSansSemiBold',
                },
                heading5: {
                  fontFamily: 'OpenSansSemiBold',
                },
                heading6: {
                  fontFamily: 'OpenSansSemiBold',
                },
              }}
            >
              {suggestions}
            </Markdown>
          </View>
        ) : null}
      </View>
    </View>
  );
};
