import { useMutation, useQuery } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import pickBy from 'lodash/pickBy';
import { PropsWithChildren, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { Linking } from 'react-native';
import { z } from 'zod';

import { AuthenticationSettings } from '@oui/app-core/src/components/AuthenticationSettings';
import { Button } from '@oui/app-core/src/components/Button';
import { HeaderButtons, HeaderItem } from '@oui/app-core/src/components/HeaderButtons';
import { ScrollView } from '@oui/app-core/src/components/ScrollView';
import { TabHeader } from '@oui/app-core/src/components/TabHeader';
import { Heading, Label, Text } from '@oui/app-core/src/components/Text';
import { UserAddress } from '@oui/app-core/src/components/UserAddress';
import { View } from '@oui/app-core/src/components/View';
import { manifest } from '@oui/app-core/src/constants';
import {
  Controller,
  PickerInputRender,
  SwitchInputRender,
  TextInputRender,
  useZodForm,
  useZodFormContext,
} from '@oui/app-core/src/form';
import { type CurrentUserQueryName } from '@oui/app-core/src/hooks/useCurrentUser';
import { useLogout } from '@oui/app-core/src/hooks/useLogout';
import { useI18n } from '@oui/app-core/src/lib/i18n';
import Sentry from '@oui/app-core/src/sentry';
import { useTheme } from '@oui/app-core/src/styles';
import { getPatientSupporterRelation } from '@oui/lib/src/getPatientSupporterRelation';
import { graphql } from '@oui/lib/src/graphql/tada';
import states from '@oui/lib/src/metadata/states.json';
import {
  PatientSupporterRelation,
  PatientSupporterRelationInputSchema,
  UserAddressInputSchema,
  UserNameInputSchema,
} from '@oui/lib/src/types';

import { TabScreenProps } from '@src/types/navigation';

export const CommonSupportUserFragment = graphql(`
  fragment CommonSupportUser on OuiUserType @_unmask {
    __typename
    ... on OuiAdmin {
      ID
    }
    ... on Registrar {
      ID
    }
    ... on Practitioner {
      ID
    }
    ... on Patient {
      ID
      profile {
        patient {
          ID
        }
        supportees {
          __typename
          supporterID
          patientID
          relation
          patient {
            __typename
            ID
            person {
              givenName
              familyName
            }
          }
        }
      }
    }
  }
`);

const SupportAccountSettingsQueryName = 'SupportAccountSettings';
export const SupportAccountSettingsQuery = graphql(
  `
    query SupportAccountSettings {
      ouiUser {
        __typename
        userID
        primaryOrganization {
          __typename
          ID
          isTrialOrganization
        }
        user {
          ...CommonSupportUser
        }
        pushNotificationsDisabled
      }
      currentUser {
        __typename
        ID
        email
        phone
        name {
          first
          last
          preferred
          pfx
          sfx
        }
        demo {
          DOB
        }
        address {
          city
          country
          line1
          line2
          zip
          state
        }
      }
    }
  `,
  [CommonSupportUserFragment],
);

export const SaveSupportAccountSettingsMutation = graphql(`
  mutation SaveSupportAccountSettings(
    $name: UserNameInput
    $address: UserAddressInput
    $phone: String
    $relation: PatientSupporterRelationInput!
  ) {
    updateInfo(name: $name, address: $address, phone: $phone)
    setPatientSupporterRelation(input: $relation) {
      __typename
      supporterID
      patientID
      relation
    }
  }
`);

export const SetPushNotificationsDisabledMutation = graphql(`
  mutation SetPushNotificationsDisabled($pushNotificationsDisabled: Boolean!) {
    setPushNotificationsDisabled(pushNotificationsDisabled: $pushNotificationsDisabled) {
      __typename
      userID
      pushNotificationsDisabled
    }
  }
`);

const SaveSupportAccountSettingsMutationSchema = z.object({
  name: UserNameInputSchema.nullish(),
  address: UserAddressInputSchema.nullish(),
  phone: z.string().nullish(),
  relation: PatientSupporterRelationInputSchema,
});

// TODO IFU for teen trial companion app
const IFU_URL =
  'https://storage.googleapis.com/asset.oui.dev/static/InstructionsforUse_23July2021.pdf';

function Subheading(props: { text: string }) {
  const { Color } = useTheme();
  return (
    <Text
      text={props.text}
      color={Color.styleGuide.Gray3}
      style={{ marginBottom: 6 }}
      size={15}
      weight="semibold"
    />
  );
}

function Section(props: PropsWithChildren<{ title: string }>) {
  return (
    <View style={{ gap: 10 }}>
      <Heading text={props.title} level={2} />
      {props.children}
    </View>
  );
}

function AccountSection() {
  const route = useRoute<TabScreenProps<'Account'>['route']>();
  const { data } = useQuery(SupportAccountSettingsQuery);
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();
  const { control } = useZodFormContext(SaveSupportAccountSettingsMutationSchema);

  return (
    <Section title={$t({ id: 'Account_accountHeading', defaultMessage: 'Account' })}>
      {isEditing ? (
        <>
          <Controller
            control={control}
            render={(props) =>
              TextInputRender(props, {
                label: $t({
                  id: 'Account_givenNameLabel',
                  defaultMessage: 'First name',
                }),
              })
            }
            name="name.first"
          />
          <Controller
            control={control}
            render={(props) =>
              TextInputRender(props, {
                label: $t({
                  id: 'Account_familyNameLabel',
                  defaultMessage: 'Last name',
                }),
              })
            }
            name="name.last"
          />
        </>
      ) : (
        <View spacing={20}>
          <>
            <Subheading text={$t({ id: 'Account_nameLabel', defaultMessage: 'Name' })} />
            <Text
              testID="Account_name"
              text={`${data?.currentUser.name.first} ${data?.currentUser.name.last}`}
            />
          </>
          <>
            <Subheading text={$t({ id: 'Account_emailLabel', defaultMessage: 'Email' })} />
            <Text testID="Account_email" text={data?.currentUser.email ?? ''} />
          </>
          <AuthenticationSettings />
        </View>
      )}
    </Section>
  );
}

function ConnectedSection() {
  const route = useRoute<TabScreenProps<'Account'>['route']>();
  const { data } = useQuery(SupportAccountSettingsQuery);
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();
  const { control } = useZodFormContext(SaveSupportAccountSettingsMutationSchema);
  const supportee =
    data?.ouiUser?.user?.__typename === 'Patient' ? data.ouiUser.user.profile.supportees[0] : null;

  const patient = (
    <>
      <Subheading text={$t({ id: 'Account_patientLabel', defaultMessage: 'Patient' })} />
      <Text
        testID="ConnectedSection_patientName"
        text={[
          supportee?.patient.person.givenName ?? '',
          supportee?.patient.person.familyName ?? '',
        ].join(' ')}
      />
    </>
  );
  return (
    <Section title={$t({ id: 'Account_connectedHeading', defaultMessage: 'Connected' })}>
      {isEditing ? (
        <View spacing={20}>
          {patient}
          <>
            <Controller
              control={control}
              render={(props) =>
                PickerInputRender(props, {
                  items: Object.values(PatientSupporterRelation).map((v) => ({
                    label: getPatientSupporterRelation({ $t, patientSupporterRelation: v }),
                    value: v,
                  })),
                  label: $t({
                    id: 'Account_patientRelationLabel',
                    defaultMessage: 'Relation',
                  }),
                })
              }
              name="relation.relation"
            />
          </>
        </View>
      ) : (
        <View spacing={20}>
          {patient}
          <>
            <Subheading
              text={$t({
                id: 'Account_patientRelationHeading',
                defaultMessage: 'Your relation',
              })}
            />
            <Text
              testID="ConnectedSection_patientRelation"
              text={
                supportee?.relation
                  ? getPatientSupporterRelation({
                      $t,
                      patientSupporterRelation: supportee.relation,
                    })
                  : ''
              }
            />
          </>
        </View>
      )}
    </Section>
  );
}

function ContactSection() {
  const route = useRoute<TabScreenProps<'Account'>['route']>();
  const { data } = useQuery(SupportAccountSettingsQuery);
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();
  const { control } = useZodFormContext(SaveSupportAccountSettingsMutationSchema);

  return (
    <Section title={$t({ id: 'Account_contactHeading', defaultMessage: 'Contact information' })}>
      {isEditing ? (
        <View testID="ContactSection_edit" spacing={8}>
          <>
            <Text
              accessibilityRole="none"
              text={$t({ id: 'Account_addressLabel', defaultMessage: 'Home address' })}
              style={{ marginLeft: 16, marginBottom: 5 }}
              weight="semibold"
            />
            <Controller
              control={control}
              render={(props) =>
                TextInputRender(props, {
                  accessibilityLabel: $t({
                    id: 'Account_addressLine1Label',
                    defaultMessage: 'Street address',
                  }),
                  placeholder: $t({
                    id: 'Account_addressLine1Placeholder',
                    defaultMessage: 'Street address',
                  }),
                })
              }
              name="address.line1"
            />
            <Controller
              control={control}
              render={(props) =>
                TextInputRender(props, {
                  accessibilityLabel: $t({
                    id: 'Account_addressLine2Label',
                    defaultMessage: 'Street address',
                  }),
                  placeholder: $t({
                    id: 'Account_addressLine2Placeholder',
                    defaultMessage: 'Street address',
                  }),
                })
              }
              name="address.line2"
            />
            <View row spacing={12} style={{ flexWrap: 'wrap' }}>
              <Controller
                control={control}
                render={(props) =>
                  TextInputRender(props, {
                    accessibilityLabel: $t({
                      id: 'Account_addressCityLabel',
                      defaultMessage: 'City',
                    }),
                    placeholder: $t({
                      id: 'Account_addressCityLabel',
                      defaultMessage: 'City',
                    }),
                    style: { width: 200 },
                  })
                }
                name="address.city"
              />
              <Controller
                control={control}
                render={(props) =>
                  PickerInputRender(props, {
                    accessibilityLabel: $t({
                      id: 'Account_addressStateLabel',
                      defaultMessage: 'State',
                    }),
                    style: { width: 150 },
                    items: states.map((s) => ({ label: s.name, value: s.abbreviation })),
                  })
                }
                name="address.state"
              />
              <Controller
                control={control}
                render={(props) =>
                  TextInputRender(props, {
                    accessibilityLabel: $t({
                      id: 'Account_addressZipLabel',
                      defaultMessage: 'Zip code',
                    }),
                    placeholder: $t({
                      id: 'Account_addressZipLabel',
                      defaultMessage: 'Zip code',
                    }),
                    style: { width: 100 },
                  })
                }
                name="address.zip"
              />
            </View>
          </>
          <Controller
            control={control}
            render={(props) =>
              TextInputRender(props, {
                label: $t({
                  id: 'Account_phoneLabel',
                  defaultMessage: 'Phone number',
                }),
                placeholder: $t({
                  id: 'Account_phonePlaceholder',
                  defaultMessage: 'Phone number',
                }),
                autoComplete: 'tel',
                disabled: !isEditing,
                keyboardType: 'phone-pad',
                textContentType: 'telephoneNumber',
              })
            }
            name="phone"
          />
        </View>
      ) : data && data.currentUser ? (
        <View spacing={20}>
          {data?.currentUser.address && Object.keys(pickBy(data.currentUser.address)).length ? (
            <>
              <Subheading
                text={$t({ id: 'Account_addressLabel', defaultMessage: 'Home address' })}
              />
              <UserAddress {...data.currentUser.address} />
            </>
          ) : null}
          <>
            <Subheading text={$t({ id: 'Account_phoneLabel', defaultMessage: 'Phone number' })} />
            <Text
              testID="ContactSection_phone"
              text={
                data.currentUser.phone ||
                $t({ id: 'Account_unknownValue', defaultMessage: 'Unknown' })
              }
            />
          </>
        </View>
      ) : null}
    </Section>
  );
}

const AllowPushSchema = z.object({
  allowPush: z.boolean(),
});

function NotificationSection() {
  const { $t } = useI18n();
  const { data } = useQuery(SupportAccountSettingsQuery, { fetchPolicy: 'cache-only' });
  const [setPush] = useMutation(SetPushNotificationsDisabledMutation);
  const form = useZodForm(AllowPushSchema, {});
  const formRef = useRef(form);
  formRef.current = form;
  const { control, watch } = form;

  const allowPush = watch('allowPush');

  const resetForm = useCallback(() => {
    if (data?.currentUser && data.ouiUser) {
      formRef.current.reset(
        { allowPush: !data.ouiUser.pushNotificationsDisabled },
        { keepDirtyValues: true },
      );
    }
  }, [data?.currentUser, data?.ouiUser]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (
      typeof allowPush === 'boolean' &&
      typeof data?.ouiUser?.pushNotificationsDisabled === 'boolean'
    ) {
      // prevent sending unecessary mutations
      if (data?.ouiUser?.pushNotificationsDisabled !== !allowPush) {
        setPush({ variables: { pushNotificationsDisabled: !allowPush } });
      }
    }
  }, [setPush, allowPush, data?.ouiUser]);

  return (
    <Section
      title={$t({
        id: 'AccountSettings_notificationsHeading',
        defaultMessage: 'Notification settings',
      })}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <Label
          text={$t({
            id: 'Account_pushNotificationsLabel',
            defaultMessage: 'Allow push notifications',
          })}
          accessibilityRole="none"
        />
        <Controller
          control={control}
          render={(props) =>
            SwitchInputRender(props, {
              accessibilityLabel: $t({
                id: 'Account_pushNotificationsLabel',
                defaultMessage: 'Allow push notifications',
              }),
            })
          }
          name="allowPush"
        />
      </View>
    </Section>
  );
}

function LegalSection() {
  const navigation = useNavigation<TabScreenProps<'Account'>['navigation']>();
  const route = useRoute<TabScreenProps<'Account'>['route']>();
  const { data } = useQuery(SupportAccountSettingsQuery);
  const isEditing = route.params?.isEditing === 'true';
  const { $t } = useI18n();

  return isEditing ? null : (
    <Section title={$t({ id: 'AccountSettings_legalHeading', defaultMessage: 'Legal' })}>
      <>
        <View spacing={8}>
          <Button
            variant="text"
            text={$t({
              id: 'AccountSettings_termsOfServiceLink',
              defaultMessage: 'Terms of service & privacy policy',
            })}
            onPress={() => {
              navigation.navigate('TermsAndPrivacy');
            }}
          />
          {data?.ouiUser?.primaryOrganization?.isTrialOrganization ? (
            <>
              <Button
                variant="text"
                text={$t({
                  id: 'AccountSettings_instructionsForUseLink',
                  defaultMessage: 'Instructions for use',
                })}
                onPress={() => {
                  Linking.openURL(IFU_URL);
                }}
              />
              <Text
                text={$t({
                  id: 'AccountSettings_investigationalDevice',
                  defaultMessage:
                    'CAUTION—Investigational device. Limited by Federal law to investigational use.',
                })}
              />
              <Text
                text={$t(
                  {
                    id: 'AccountSettings_investigationalDeviceVersion',
                    defaultMessage: 'Version: {version}',
                  },
                  { version: manifest.version },
                )}
              />
            </>
          ) : null}
        </View>
      </>
    </Section>
  );
}

export function Account(_props: {}) {
  const navigation = useNavigation<TabScreenProps<'Account'>['navigation']>();
  const route = useRoute<TabScreenProps<'Account'>['route']>();
  const { $t } = useI18n();
  const { data } = useQuery(SupportAccountSettingsQuery);
  const [updateUser] = useMutation(SaveSupportAccountSettingsMutation);
  const logout = useLogout();
  const isEditing = route.params?.isEditing === 'true';
  const { theme } = useTheme();

  const form = useZodForm(SaveSupportAccountSettingsMutationSchema, {});
  const formRef = useRef(form);
  formRef.current = form;

  const resetForm = useCallback(
    (keepDirtyValues: boolean = true) => {
      if (data?.currentUser && data.ouiUser) {
        const supportee =
          data.ouiUser.user?.__typename === 'Patient'
            ? data.ouiUser.user.profile.supportees[0]
            : null;

        if (!supportee) {
          Sentry.captureMessage('AVIVA_SUPPORT patient has no supportees');
        }

        formRef.current.reset(
          {
            name: {
              first: data.currentUser.name.first,
              last: data.currentUser.name.last,
              preferred: data.currentUser.name.preferred,
              pfx: data.currentUser.name.pfx,
              sfx: data.currentUser.name.sfx,
            },
            phone: data.currentUser.phone,
            address: {
              line1: data.currentUser.address?.line1 ?? '',
              line2: data.currentUser.address?.line2 ?? '',
              city: data.currentUser.address?.city ?? '',
              state: data.currentUser.address?.state ?? '',
              country: '', // unused field, but required
              zip: data.currentUser.address?.zip ?? '',
            },
            relation: { patientID: supportee?.patient.ID, relation: supportee?.relation },
          },
          { keepDirtyValues },
        );
      }
    },
    [data?.currentUser, data?.ouiUser],
  );

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  const onSave = useCallback(async () => {
    await formRef.current.handleSubmit(async (data) => {
      await updateUser({
        variables: data,
        refetchQueries: [
          SupportAccountSettingsQueryName,
          'CurrentUser' satisfies CurrentUserQueryName,
        ],
      });
      navigation.setParams({ isEditing: 'false' });
      resetForm();
    })();
  }, [updateUser, resetForm, navigation]);

  useLayoutEffect(() => {
    if (isEditing) {
      navigation.setOptions({
        header: undefined,
        headerTitle: $t({ id: 'Account_editHeading', defaultMessage: 'Edit account settings' }),
        headerRight: () => (
          <HeaderButtons>
            <Button
              text={$t({ id: 'Account_saveButton', defaultMessage: 'Save' })}
              testID="Account_saveButton"
              alignSelf="flex-start"
              onPress={onSave}
              style={{ paddingHorizontal: 14 }}
            />
          </HeaderButtons>
        ),
        headerLeft: ({ tintColor }) => (
          <HeaderButtons>
            <HeaderItem
              testID="Account_cancelButton"
              accessibilityLabel={$t({
                id: 'Account_cancelButton',
                defaultMessage: 'Cancel',
              })}
              title=""
              onPress={() => {
                resetForm(false);
                navigation.setParams({ isEditing: 'false' });
              }}
              iconName="close"
              color={tintColor}
            />
          </HeaderButtons>
        ),
      });
    } else {
      navigation.setOptions({
        header: ({ options: { headerTintColor: tintColor } }) => (
          <TabHeader
            borderBottom
            heading={$t({ id: 'Account_heading', defaultMessage: 'Account' })}
            headerRight={
              <HeaderItem
                accessibilityLabel={$t({ id: 'Account_editButton', defaultMessage: 'Edit' })}
                title=""
                onPress={() => navigation.setParams({ isEditing: 'true' })}
                iconName="edit"
                color={tintColor}
                testID="Account_editButton"
              />
            }
          />
        ),
      });
    }
  }, [navigation, isEditing, onSave, $t, resetForm, theme]);

  return (
    <ScrollView testID="Account">
      <FormProvider {...form}>
        <View style={{ padding: 20 }} spacing={60}>
          <AccountSection />
          <ConnectedSection />
          <ContactSection />
          <NotificationSection />
          <LegalSection />
          {isEditing ? null : (
            <Button
              style={{ marginTop: 40 }}
              text={$t({
                id: 'Account_logOutLink',
                defaultMessage: 'Log out',
              })}
              variant="text"
              onPress={logout}
            />
          )}
        </View>
      </FormProvider>
    </ScrollView>
  );
}
